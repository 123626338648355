<template>
	<div>
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" style="margin-right:10px" />
				<span>{{ this.isEdit ? l('Edit') : l('Create') }}</span>
			</div>
		</div>
		<div>
			<a-config-provider :locale="zh_CN">
				<a-row>
					<a-col :span="24">
						<a-col :span="14">
							<a-form-item required :label-col="{ span: 14 }" :wrapper-col="{ span: 4 }"
								:label="l('layoutlevel.levelindex')" class="body">
								<a-input-number v-model="levelIndex" @change="issueChange" :min="0"></a-input-number>
							</a-form-item>
						</a-col>
						<a-col :span="10">
							<span v-if="prompt" style="color: red;">{{ l('this_progress_value_already') }}</span>
						</a-col>
					</a-col>
					<a-col :span="24">
						<a-col :span="8">

						</a-col>
						<a-col :span="16">
							<p style="color: red;">{{ l('The_smaller_the_number') }}</p>
						</a-col>
					</a-col>
					<a-col :span="24">
						<a-form-item required :label-col="{ span: 8 }" :wrapper-col="{ span: 9 }" :label="l('Name')"
							class="body">
							<a-input v-model="entity.name" class="input"></a-input>
						</a-form-item>
					</a-col>
					<a-col :span="24">
						<a-form-item required :label-col="{ span: 8 }" :wrapper-col="{ span: 4 }"
							:label="l('layoutlevel.msginterval')" class="body">
							<a-input-number v-model="msgInterval" @change="issueChange" :min="0"></a-input-number>
						</a-form-item>
					</a-col>
					<a-col :span="24">
						<a-col :span="8">

						</a-col>
						<a-col :span="16">
							<p style="color: red;">{{ l('How_many_days') }}</p>
						</a-col>
					</a-col>
					<a-col :span="24">
						<a-col :span="8">
							<a-form-item :label="l('Remark')">

							</a-form-item>
						</a-col>
						<a-col :span="16">
							<a-textarea v-model="entity.remark" style="height: 80px;width:200px;"></a-textarea>
						</a-col>
					</a-col>

					<a-col :span="24">
						<a-col :span="14">
							<a-form-item :label-col="{ span: 14 }" :wrapper-col="{ span: 4 }"
								:label="l('Is_displayed_Kanban')" class="body">
								<a-switch default-checked v-model="entity.isKanBanShow" />
							</a-form-item>
						</a-col>
					</a-col>

					<a-col :span="24">
						<a-col :span="14">
							<a-form-item :label-col="{ span: 14 }" :wrapper-col="{ span: 4 }"
								:label="l('Warning_Days')" class="body">
								<a-input-number v-model="entity.timeoutDays" :min="0"></a-input-number>
							</a-form-item>
						</a-col>
					</a-col>

				</a-row>
			</a-config-provider>
		</div>
		<div class="modal-footer" v-if="!isLook">
			<a-button @click="close">
				{{ l('Cancel') }}
			</a-button>
			<a-button :type="'primary'" @click="save">
				{{ l('Save') }}
			</a-button>
		</div>
	</div>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import { LayoutLevelServiceProxy } from '../../../../shared/service-proxies';

let _this;
export default {
	name: 'new-level',
	mixins: [ModalComponentBase],
	components: {

	},
	created() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this.LayoutLevelServiceProxy = new LayoutLevelServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.getData();
	},
	data() {
		return {
			zh_CN,
			isEdit: false,//是否是编辑
			isLook: false,//是否是查看
			entity: [],
			msgInterval: undefined,
			levelIndex: undefined,
			prompt: undefined,
			isclick: true,
		}
	},
	methods: {
		getData() {
			this.isLoading = true;
			this.LayoutLevelServiceProxy.getForEdit(
				this.id
			).finally(() => {
				this.isLoading = false;
			}).then(res => {
				// console.log(res);
				this.entity = res;
				if (this.entity.msgInterval == 0) {
					this.msgInterval = 0;
				} else {
					this.msgInterval = this.entity.msgInterval;
				}
				if (this.entity.levelIndex == 0) {
					this.levelIndex = null;
				} else {
					this.levelIndex = this.entity.levelIndex;
				}
			})
		},
		issueChange() {
			if (isNaN(this.levelIndex)) {
				return abp.message.warn(this.l('Theinputisnotanumber'))
			} else {
				if (this.levelIndex < 0) {
					this.prompt = true;
				} else {
					this.prompt = false;
				}
			};
			if (isNaN(this.msgInterval)) {
				return abp.message.warn(this.l('Theinputisnotanumber'))
			} else {
				if (this.msgInterval < 0) {
					this.prompt = true;
				} else {
					this.prompt = false;
				}
			}

		},
		save() {
			if (!this.entity.name) {
				abp.message.info(this.l('level_name_tip'));
				return;
			}
			if (!this.levelIndex) {
				abp.message.info(this.l('level_level_tip'));
				return;
			}
			if (this.msgInterval == undefined) {
				abp.message.info(this.l('level_day_tip'));
				return;
			}

			this.entity.msgInterval = this.msgInterval;
			this.entity.levelIndex = this.levelIndex;
			var layoutLevelEditDto = { layoutLevelEditDto: this.entity };
			// console.log(auditItemTypeEditDto);
			if (this.isclick) {
				this.isclick = false;
				this.LayoutLevelServiceProxy.createOrUpdate(
					layoutLevelEditDto
				).finally(() => {
					this.close()
				}).then(res => {
					this.isclick = true;
					this.success(true)
				})
			}
		},

	}
}
</script>

<style>
.input {
	width: 200px;
}

.body {
	margin-bottom: 10px;
}
</style>
